<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" class="mb10">
				<el-form-item>
					<el-date-picker v-model="time" type="daterange"	align="right" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				</el-form-item>
				<!-- <el-form-item>
					<el-input v-model="name" placeholder="店铺名查询" class="w120"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="username" placeholder="店铺号查询" class="w120"></el-input>
				</el-form-item> -->
				<el-form-item>
					<el-input v-model="order_num" placeholder="方案号查询" class="w120"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="prize_cash" placeholder="中奖状态" clearable class="w120">
						<el-option v-for="item in zhongjianStatus" :key="item.value" :label="item.text" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="is_upload_tkimg" placeholder="是否传票" clearable class="w120">
						<el-option v-for="item in chuanpiaoStatus" :key="item.value" :label="item.text" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="get_ticket" placeholder="是否取票" clearable class="w120">
						<el-option v-for="item in qupiaoStatus" :key="item.value" :label="item.text" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- choucha -->
				<el-form-item>
					<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
			<!-- <div class="col-blue">以下方案均为超时未上传票样方案，需联系店主尽快处理。</div> -->
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="order_num" label="方案号" width="120" align="center"></el-table-column>
			<el-table-column prop="createtime" label="接单时间" width="150"></el-table-column>
			<el-table-column label="金额/票数" width="110" align="center">
				<template slot-scope="scope">{{scope.row.money}}/{{scope.row.ticket}}</template>
			</el-table-column>
			<el-table-column prop="username" label="店铺账号" width="100" align="center"></el-table-column>
			<el-table-column prop="name" label="店铺名" width="110" align="center"></el-table-column>
			<el-table-column label="中奖状态" width="100" align="center">
				<template slot-scope="scope">{{ scope.row.prize_cash == 0 ? '未开奖': scope.row.prize_cash == 5 ? '未中奖': scope.row.prize_cash == 10 ? '已中奖': scope.row.prize_cash == 20 ? '已兑奖':scope.row.prize_cash == 30 ? '已中不兑':''}}</template>
			</el-table-column>
			<el-table-column label="是否取票" align="center">
				<template slot-scope="scope">{{ scope.row.get_ticket == 1 ? '未取票':'已取票'}}</template>
			</el-table-column>
			<el-table-column label="票样" align="center"> 
				<template slot-scope="scope">
					<el-button v-if="scope.row.tk_img" @click="seeTkimg(scope.row.tk_img)">查看票样</el-button>
					<div v-else>未上传</div>
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page"
				:page-sizes="pagesizes"
				:page-size="pagesize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-col>
		<showimg v-if="imgList.length > 0" :imgList="imgList" @hideImg="hideImg"></showimg>
	</section>
</template>

<script>
	let _t;
	import showimg from '@/components/showImgList'
	import { getPlanList } from '../../api/api';
	import common from '@/utils/common';
	export default {
		components: { showimg },
		data() {
			return {
				order_num: '',
				is_upload_tkimg: '2',
				time:[],
				username: '',
				name: '',
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				zhongjianStatus: [
					{ value: '0', text: '未开奖' },
					{ value: '5', text: '未中奖' },
					{ value: '10', text: '已中奖' },
					{ value: '20', text: '已兑奖' },
					{ value: '30', text: '已中不兑' },
				],
				chuanpiaoStatus: [
					{ value: '0', text: '全部' },
					{ value: '2', text: '未传票' },
					{ value: '1', text: '已传票' },
				],
				qupiaoStatus: [
					{ value: '0', text: '全部' },
					{ value: '1', text: '未取票' },
					{ value: '2', text: '已取票' },
				],
				prize_cash: '',
				get_ticket: '',
				imgList: []
			}
		},
		created() {
			_t = this
			_t.username = _t.$route.query.id
			_t.time = [common.getDay(-3), common.dateFormater('YYYY-MM-DD')];
			//获取列表
			_t.getList(1);
		},
		methods: {
			hideImg(){
				_t.imgList = []
			},
			seeTkimg(imgs){
				if(!imgs) return false
				_t.imgList = JSON.parse(imgs)
			},
			search(value,row,column){
				console.log('search')
				console.log(value, row, column)
			},
			//分页
			handleSizeChange(val) {
				_t.pagesize = val;
				_t.getList();
			},
			handleCurrentChange(val) {
				_t.page = val;
				_t.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') _t.page = 1
				const para = {
					page: _t.page,
					pagesize: _t.pagesize,
					order_num: _t.order_num,
					username: _t.username,
					name: _t.name,
					start_time: _t.time[0],
					end_time: _t.time[1],
					prize_cash: _t.prize_cash,
					get_ticket: _t.get_ticket,
					is_upload_tkimg: _t.is_upload_tkimg
				};
				_t.listLoading = true;
				getPlanList(para).then((res) => {
					if(res.code == 1){
						_t.total = res.data.total;
						_t.listData = res.data.list;
						_t.listLoading = false;
					}else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
		},
	}
</script>
<style lang="less" scoped>
.el-form-item{ margin-bottom: 6px; }
.img1{ width: 64px; height: 64px; }
.mlr10{ margin: 0 10px;}
.mText{ width: 500px; border: none; background-color: #f7f7f7; padding: 10px; box-sizing: border-box; border-radius: 5px; }
</style>
